import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import { markdownParser } from '../../utils';
import Layout from '../../components/layout';
import Container from '../../components/Container';
import { profiles } from '../../../content/the_band/profiles.json';
import bookUs from '../../../content/book_us/bookUs.json';
import s from './BookUs.scss';
import Announcement from '../../components/Announcement/Announcement';

const contactProfiles = profiles.filter((p) => p.showBookUs);
const {
  topSection: { title, subtitle, body },
  contactInfos,
  announcement,
} = markdownParser(bookUs);
const BookUs = ({ location }) => (
  <Layout location={location}>
    <div className="BookUs">
      <div className="banner bgMidnight" />
      <section className="bgSky top">
        <Container justifyCenter>
          <div className="mobile-only mobile-illustration">
            <img src="/decorations/cloud2.svg" alt="" className="cloud1" />
            <div>
              <img
                src="/decorations/book-us-mobile.svg"
                alt=""
                className="phoneBooth"
                height="150px"
              />
              <img src="/decorations/cloud1.svg" alt="" className="cloud2" />
            </div>
          </div>

          <div className="titleWrapper">
            <h1 className="title">
              <b>{title}</b>
              {subtitle}
            </h1>
            <p>{body}</p>
          </div>

          <div className="illustration">
            <img src="/decorations/cloud1.svg" alt="" className="cloud1" />
            <img src="/decorations/cloud2.svg" alt="" className="cloud2" />
            <img
              src="/decorations/phonebooth.svg"
              alt=""
              className="phoneBooth"
            />
          </div>
        </Container>
        <hr className="spikes" />
      </section>

      <section className="bgAccentLighter">
        <Container>
          <Container className="contactContainer">
            {contactInfos.map(
              ({
                title: contactTitle,
                map,
                mapLink,
                mapLinkTitle,
                email,
                attention,
                streetNr,
                zip,
                city,
                organization,
                orgNr,
              }) => (
                <div className="contactInfo">
                  <Link to={mapLink}>
                    <div className="map">
                      <div style={{ backgroundImage: `url(${map})` }} />
                      <p className="hover">{mapLinkTitle}</p>
                    </div>
                  </Link>
                  <h6>{contactTitle}</h6>
                  <a href={`mailto:${email}`} className="email">
                    {email}
                  </a>
                  <div className="address">
                    <span>{attention}</span>
                    <span>{streetNr}</span>
                    <span>
                      {zip} {city}
                    </span>
                  </div>
                  <div>{organization}</div>
                  <div>Org.Nr: {orgNr}</div>
                </div>
              ),
            )}
          </Container>
          <div className="contacts">
            {contactProfiles.map((p) => (
              <div className="profile" key={`${p.slug}_${Math.random()}`}>
                <Link to={`/the-band/${p.slug}`} className="profileImage">
                  <img src={p.image} alt={`${p.workTitle} ${p.name}`} />
                </Link>
                <div className="profileText">
                  <h6>{p.bookUsTitle}</h6>
                  <span>
                    <b>{p.name}</b>
                  </span>
                  <a href={`mailto:${p.email}`}>{p.email}</a>
                  <span>{p.phone}</span>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>
      <Announcement
        title={announcement.title}
        subtitle={announcement.subtitle}
        design="bgPrimaryLightest"
        button={{
          link: announcement.buttonLink,
          text: announcement.buttonText,
          design: 'simplePrimary',
        }}
      />
    </div>
  </Layout>
);

BookUs.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default withStyles(s)(BookUs);
